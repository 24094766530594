//@flow
import React from 'react'
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from "@mui/material";
import InputCounter from "../counter/InputCounter";
import PropTypes from "prop-types";
import {ExerciseSet} from "../../../model/ExerciseSet";
import {useDispatch} from "react-redux";
import {setAllPerformed, setPerformed, setSlotEnabled} from "../../../store/store";

function CircularProgressWithLabel(props) {
    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress variant="determinate" {...props} />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography variant="caption" component="div" color="text.secondary">
                    {`${Math.round(props.value)}%`}
                </Typography>
            </Box>
        </Box>
    );
}


function ExerciseCard({slot, gender}) {

    const enabled = slot.enabled
    const allPerformed = slot.allPerformed;
    const dispatch = useDispatch();

    let value;
    if (allPerformed && enabled) {
      value = "yes";
    } else if (!enabled) {
      value = "skip"
    } else {
      value = "no"
    }

    let cardCss = {
        borderRadius: 2
    };

    if (value === "skip") {
        cardCss = {
            ...cardCss,
            '-webkit-filter': 'grayscale(1)',
            '-moz-filter': 'grayscale(100%)',
            '-ms-filter': 'grayscale(100%)',
            '-o-filter': 'grayscale(100%)',
            filter: 'grayscale(100%)'
        }
    }

    const handleChange = (event, newAlignment) => {
      switch (newAlignment) {
        case "yes":
          return dispatch(setAllPerformed({exercise: slot.exercise}))
        case "no":
          return dispatch(setPerformed({exercise: slot.exercise}))
        default:
          return dispatch(setSlotEnabled({exercise: slot.exercise, enabled: false}))
      }
    };

    return (
        <Card sx={cardCss}>
            <Box sx={{position: "relative"}}>
                <CardMedia
                    component="img"
                    height="sm"
                    image={"/images/exercises/" + gender + "/" + slot.exercise.toLowerCase() + ".png"}
                    alt="Live from space album cover"
                />
            </Box>

            <CardContent sx={{flex: '1 0 auto'}}>
                <Typography component="div"  variant="subtitle1" align="center">Получилось выполнить все подходы?</Typography>
                <ToggleButtonGroup
                    color="primary"
                    fullWidth={true}
                    value={value}
                    exclusive
                    onChange={handleChange}
                >
                    <ToggleButton value="yes">&#x1F44D; Да</ToggleButton>
                    <ToggleButton value="no">&#x1F44E; Нет</ToggleButton>
                    <ToggleButton value="skip">&#x274C; Пропустил</ToggleButton>
                </ToggleButtonGroup>

                {value === "no" && (
                    <Box>
                        <Typography component="div"  variant="subtitle1" align="center" sx={{paddingTop: '10px'}}>Сколько получилось?</Typography>
                        {slot.sets.map((set: ExerciseSet, setNum) => (
                            <Box key={slot.exercise + "_" + setNum}>
                                <Box sx={{paddingTop: '10px'}}>
                                    <InputCounter
                                        min={0}
                                        max={set.target}
                                        current={set.performed}
                                        units={slot.units === "REPS" ? "раз" : "сек"}
                                        disabled={!enabled}
                                        onUpdate={(newValue) => dispatch(setPerformed({exercise: slot.exercise, setNumber: setNum, performed: newValue}))}
                                    />
                                </Box>
                                <Typography component="div" variant="caption" align="center">
                                    Подход {setNum + 1}
                                </Typography>
                            </Box>
                        ))}
                    </Box>
                )}


            </CardContent>

        </Card>
    )
}

ExerciseCard.propTypes = {
    slot: PropTypes.any
}

export default ExerciseCard;