// @flow
class ErrorDescription {

    status: string;

    constructor(status: string) {
        this.status = status;
    }
}

export default ErrorDescription;