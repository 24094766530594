//@flow

const AppSettings = {
    "192.168.5.94": {
        apiUrl: "http://localhost:8099"
    },

    "localhost": {
        apiUrl: "http://localhost:8099"
    },

    "wr.ptbt.ru": {
        apiUrl: "https://wr.ptbt.ru"
    },

    "wr.ptbt.site": {
        apiUrl: "https://wr.ptbt.site"
    },

    "wr.gbbt.ru": {
        apiUrl: "https://wr.gbbt.ru"
    }
};



export default AppSettings[window.location.hostname]