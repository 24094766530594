//@flow

import {Context} from "./Context";

export class ContextUpdatedEvent {
    contextId: string
    context: Context

    constructor(contextId: string, context: Context) {
        this.contextId = contextId
        this.context = context
    }
}