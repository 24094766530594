import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store, {resetContext} from './store/store'
import {Provider} from "react-redux";
import workoutService from "./service/WorkoutService";
import {Context} from "./model/Context";

function getUrlParameterByName(name, defaultValue) {
    let url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    let regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return defaultValue;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <App />
        </Provider>
    </React.StrictMode>,

    document.getElementById('root'),
    () => {
        const contextId = getUrlParameterByName("c", "test")
        workoutService
            .getContext(contextId)
            .then((w : Context) => store.dispatch(resetContext({workout : w, error: null, contextId : contextId})))
            .catch(error => store.dispatch(resetContext({workout : null, error: error.status, contextId : contextId})))
    }
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
