//@flow

import {Context} from "../model/Context";
import ErrorDescription from "../model/ErrorDescription";
import {ContextUpdatedEvent} from "../model/ContextUpdatedEvent";
import settings from "../settings";

class WorkoutService {

    contextUrl : string

    constructor(contextURL : string) {
        this.contextUrl = contextURL;
    }

    getContext(contextId : string): Promise<Context> {
        return fetch(this.contextUrl + '/redis/' + contextId + '/get')
            .then(WorkoutService._checkResponse)
            .then((response) => response.json())
            .then((responseJson) => WorkoutService._parseWorkout(responseJson))
    }

    contextUpdated(contextId : string, workout : Context) : Promise<string> {
        return fetch(
            this.contextUrl + '/redis/' + workout.notifyQueue + '/rpush',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(new ContextUpdatedEvent(contextId, workout))
            }
        )
    }

    static _checkResponse(response) {
        if (response.status === 200)
            return response;

        return response.json().then(json => {
            throw new ErrorDescription(json.status)
        });
    }

    static _parseWorkout(json: any) {
        return json;
    }
}

export default new WorkoutService(settings.apiUrl);