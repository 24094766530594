import {configureStore, createSlice} from '@reduxjs/toolkit'
import _ from "lodash";

const contextSlice = createSlice({
    name: 'context',
    initialState: {
        contextId: null,
        error: null,
        value: null
    },
    reducers: {
        reset: (state, action) => {
            state.value = action.payload.workout
            state.contextId = action.payload.contextId
            state.error = action.payload.error
        },
        setEnabled: (state, action) => {
            const {exercise, enabled} = action.payload
            const workout = {...state.value}

            const slot = _.find(workout.slots, (slot) => slot.exercise === exercise);
            slot.enabled = enabled

            state.value = workout
        },
        setAllPerformed: (state, action) => {
            const {exercise} = action.payload
            const workout = {...state.value}
            const slot = _.find(workout.slots, (s) => s.exercise === exercise);

            slot.allPerformed = true;
            slot.enabled = true;
        },
        setPerformed: (state, action) => {

            const {exercise, setNumber, performed} = action.payload
            const workout = {...state.value}

            const slot = _.find(workout.slots, (s) => s.exercise === exercise);

            slot.allPerformed = false;
            slot.enabled = true;
            if (setNumber !== undefined) {
                slot.sets[setNumber].performed = performed;
            }

            state.value = workout
        }
    },
})

export const resetContext = contextSlice.actions.reset
export const setPerformed = contextSlice.actions.setPerformed
export const setAllPerformed = contextSlice.actions.setAllPerformed
export const setSlotEnabled = contextSlice.actions.setEnabled

export const valueSelector = (state) => {return state.context.value}
export const contextIdSelector = (state) => state.context.contextId
export const errorSelector = (state) => state.context.error

export function selectExerciseTargetReps(state: any, exercise: string, setNumber: number) {
    const workout = state.context.value
    const slot = _.find(workout.slots, (slot) => slot.exercise === exercise);
    return slot.sets[setNumber].targetReps
}

export function selectExercisePerformedReps(state: any, exercise: string, setNumber: number) {
    const workout = state.context.value
    const slot = _.find(workout.slots, (slot) => slot.exercise === exercise);
    return slot.sets[setNumber].performedReps;
}

export default configureStore({
    reducer: {
        context: contextSlice.reducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false
    })
})

