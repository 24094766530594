import './App.css';
import {
    Box,
    Button,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    LinearProgress,
    Typography
} from "@mui/material";
import ExerciseCard from "./components/molecules/card/Card";
import workoutService from "./service/WorkoutService"
import {useSelector} from "react-redux";
import {contextIdSelector, errorSelector, valueSelector} from "./store/store";
import {useEffect, useState} from "react";

const useStateWithCallback = (initialState, callback) => {
    const [state, setState] = useState(initialState);
    useEffect(() => callback(state), [state, callback]);
    return [state, setState];
};

const SAVING_STATE_IN_PROGRESS = "in_progress"
const SAVING_STATE_DONE = "done"

function ErrorMessage({error}) {
    return (
        <Box sx={{ position: "absolute", top: '50%', left: '15%', width: '70%' }}>
            <Typography variant={"h6"} color={"red"} align={"center"}>Ошибка</Typography>
            <Typography variant={"overline"} component={"div"} color={"red"} align={"center"} sx={{width: '100%'}}>{error}</Typography>
        </Box>
    )
}

function LoadingMessage() {
    return (
        <Box sx={{ position: "absolute", top: '50%', left: '15%', width: '70%' }}>
            <Typography variant={"h6"} color={"black"} align={"center"}>Загрузка...</Typography>
            <LinearProgress />
        </Box>
    )
}

function ConfirmDialog({confirming, onConfirm, onClose}) {
    return (
      <Dialog
        fullWidth={true}
        open={confirming}
        keepMounted
        onClose={onClose}
        aria-describedby="alert-dialog-slide-description"
      >
          <DialogTitle>{"Отправить отчет?"}</DialogTitle>
          <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                  Отправить отчет в бота?
              </DialogContentText>
          </DialogContent>
          <DialogActions>
              <Button onClick={onConfirm}>Отправить</Button>
              <Button onClick={onClose}>Отмена</Button>
          </DialogActions>
      </Dialog>
    )
}


function SaveDialog({saving, onClose}) {
    return (
        <Dialog
            fullWidth={true}
            open={saving != null}
            keepMounted
            onClose={onClose}
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle>{"Отправка данных"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    {saving === "in_progress" &&
                    "Сохранение..."
                    }
                    {saving === "done" &&
                    "Данные успешно сохранены. Можете возвращаться в бота."
                    }
                    {saving !== "done" && saving !== "in_progress" &&
                    "Ошибка: " + saving
                    }
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button disabled={saving === "in_progress"} onClick={onClose}>Закрыть</Button>
            </DialogActions>
        </Dialog>
    )
}

function App() {

    const workout = useSelector(valueSelector)
    const contextId = useSelector(contextIdSelector)
    const error = useSelector(errorSelector)

    const [saving, setSaving] = useStateWithCallback(null, saving => {
        if (saving === SAVING_STATE_IN_PROGRESS)
            workoutService.contextUpdated(contextId, workout)
                .then(() => setSaving(SAVING_STATE_DONE))
                .catch((error) => setSaving(error))
    });

    const [confirming, setConfirming] = useState(false)

    if (workout === null) {
        if (error != null) {
            return <ErrorMessage error={error}/>
        }
        return <LoadingMessage/>
    }

    const send = () => {
        setConfirming(false)
        setSaving("in_progress")
    }

    const closeDialog = () => {
        setSaving(null)
    }

    const askSendToBot = () => {
        setConfirming(true)
    }

    const returnToEdit = () => {
        setConfirming(false)
    }

    return (
        <Box sx={{flexGrow: 1}}>
            <Typography variant={"h4"} align={"center"}>Расскажите о результатах каждого упражнения</Typography>

            <Container maxWidth="sm">
                {workout.slots.map((slot, slotNum) => (
                    <Box key={"slot_" + slotNum} sx={{paddingTop: '10px'}}>
                        <ExerciseCard slot={slot} gender={workout.gender} key={"slotcard_" + slotNum}/>
                    </Box>
                ))}
                <Button variant={"contained"} size={"large"} fullWidth={true} sx={{marginTop: 1, marginBottom: 1}} onClick={askSendToBot}>Отправить</Button>
            </Container>

            <ConfirmDialog confirming={confirming} onConfirm={send} onClose={returnToEdit}/>
            <SaveDialog saving={saving} onClose={closeDialog}/>
        </Box>
    );
}

export default App;
