import React, {useEffect, useRef, useState} from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import {ThemeProvider} from "@emotion/react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons'

const defaultTheme = {
    height: 50,
    border: {
        radius: 4
    },
    minus: {
        color: '#d32f2f',
        hoverColor: '#c62828'
    },
    plus: {
        color: '#2e7d32',
        hoverColor: '#1b5e20'
    }
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  vertical-align: middle;
  line-height: ${props => props.theme.height}px;
  /* disable text selection */
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`
const button = styled.div`
  // box-shadow: 0 3px 3px -5px #F2709C, 0 2px 5px #F2709C;
  width: ${props => props.theme.height}px;
  z-index: 1;
  border-radius: ${props => props.theme.border.radius}px;
  cursor: ${props => props.disabled ? "" : "pointer"};
  color: white;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
`
const Minus = styled(button)`
  background-color: ${props => props.theme.minus.color};
  &:hover {
    background-color: ${props => props.theme.minus.hoverColor}
  }
`
const Plus = styled(button)`
  background-color: ${props => props.theme.plus.color};
  &:hover {
    background-color: ${props => props.theme.plus.hoverColor}
  }
`
const Progress = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  flex-grow: 1
`
const ProgressBg = styled.div`
  background-color: #f4f4f4;
  line-height: ${props => props.theme.height}px;
  margin-left: -${props => props.theme.height / 2}px;
  margin-right: -${props => props.theme.height / 2}px;
`
const ProgressFill = styled.div`
  position: relative;
  // background: linear-gradient(to left, #F2709C, #FF9472);
  background-color: #90caf9;
  // box-shadow: 0 3px 3px -5px #F2709C, 0 2px 5px #F2709C;
  border-radius: 0 ${props => props.theme.border.radius}px ${props => props.theme.border.radius}px 0;
  -webkit-transition: width 200ms linear;
  -moz-transition: width 200ms linear;
  -o-transition: width 200ms linear;
  transition: width 200ms linear;
`
const ProgressCounter = styled.div`
  position: absolute;
  font-size: 20px;
  top: 0;
  left: 0;
  right: 0;
  font-family: Roboto
`

function InputCounter({min, max, current, units, disabled, onUpdate, theme}) {

    const fillRef = useRef()
    const counterRef = useRef()

    let timeout = null;
    let newVal = current;

    const incValue = (e, inc) => {
        e.preventDefault();
        if (!disabled)
            incValue2(current, inc)
    }

    const incValue2 = (cur, inc) => {
        newVal = Math.min(Math.max(min, cur + inc), max)
        fillRef.current.style.width = newVal / max * 100 + "%";
        counterRef.current.innerText = newVal + (units ? " " + units : "")
        timeout = window.setTimeout(() => incValue2(newVal, inc), 200)
    }

    const cancelInc = (e) => {
        e.preventDefault();
        if (timeout)
            window.clearTimeout(timeout)

        if (onUpdate)
            onUpdate(newVal)
    }

    const progressWidth = current / max * 100 + "%"

    const mergedTheme = {...defaultTheme, ...theme}

    return (
        <ThemeProvider theme={mergedTheme}>
            <Container>
                <Minus
                    disabled={disabled}
                    onMouseUp={(e) => cancelInc(e)} onMouseDown={(e) => incValue(e,-1)}
                    onTouchEnd={(e) => cancelInc(e)} onTouchStart={(e) => incValue(e,-1)}
                ><FontAwesomeIcon icon={faArrowLeft} /></Minus>
                <Progress>
                    <ProgressBg>
                        <ProgressFill ref={fillRef} style={{width: progressWidth}}>&nbsp;</ProgressFill>
                        <ProgressCounter ref={counterRef}>{current + (units ? " " + units : "")}</ProgressCounter>
                    </ProgressBg>
                </Progress>
                <Plus
                    disabled={disabled}
                    onMouseUp={(e) => cancelInc(e)} onMouseDown={(e) => incValue(e,+1)}
                    onTouchEnd={(e) => cancelInc(e)} onTouchStart={(e) => incValue(e,+1)}
                ><FontAwesomeIcon icon={faArrowRight} /></Plus>
            </Container>
        </ThemeProvider>
    )
}

InputCounter.propTypes = {
    min: PropTypes.number,
    max: PropTypes.number,
    current: PropTypes.number,
    units: PropTypes.string,
    disabled: PropTypes.bool,
    onUpdate: PropTypes.func,
    theme: PropTypes.any
}

export default InputCounter